import React from "react"
import PropTypes from 'prop-types'
import {Link} from "gatsby"

import "./card.scss"

const Card = ({icon, title, link, onClick}) => (
    link !== null ?
        <Link to={link} className={"sr-card sr-card-btn"} onClick={value => onClick(value)}>
            <div className={"sr-card-header"}>
                {icon}
            </div>
            <div className={"sr-card-body"}>
                {title}
            </div>
        </Link>
    :
        <div className={"sr-card sr-card-btn"} onClick={value => onClick(value)}>
            <div className={"sr-card-header"}>
                {icon}
            </div>
            <div className={"sr-card-body"}>
                {title}
            </div>
        </div>
)

Card.propTypes = {
    title:  PropTypes.string,
    icon:   PropTypes.node.isRequired,
    link:   PropTypes.string,
    onClick: PropTypes.func
}

Card.defaultProps = {
    title: "",
    link: null,
    onClick: () => {},
}

export default Card
